import { Customer, FilterCustomerDto } from '../../store/Customer';
import { PaginatedResponse } from '../../store/GeneralStore';
import { callExternalApi } from '../callExternalApi';

const API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL;

export const getCustomer = (id: string) => {
  return callExternalApi<Customer>({
    url: `${API_SERVER_URL}/account/customers/${id}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  });
};

export const listCustomers = (filters?: FilterCustomerDto) => {
  const mergedFilters: FilterCustomerDto = {
    page: 1,
    pageSize: 100,
    ...filters
  };
  let url = `${API_SERVER_URL}/account/customers?`;
  const urlParams = new URLSearchParams();
  for (const [key, value] of Object.entries(mergedFilters)) {
    if (value !== undefined) urlParams.append(key, value.toString());
  }
  url += urlParams.toString();
  return callExternalApi<PaginatedResponse<Customer>>({
    url: url,
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  });
};
