import { Commission } from '../../store/Commission';
import { callExternalApi } from '../callExternalApi';

const API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL;

export const postCommissionApi = async () => {
  return await callExternalApi<Commission>({
    url: `${API_SERVER_URL}/commission-sync-jobs`,
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    }
  });
};

export const fetchCommissionsApi = async () => {
  return await callExternalApi<Commission[]>({
    url: `${API_SERVER_URL}/commission-sync-jobs`,
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  });
};
