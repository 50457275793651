import { BehaviorSubject } from 'rxjs';
import { addError } from './Error';
import { fetchCommissionsApi, postCommissionApi } from '../Api/Commissions/commissionsApi';

export interface Commission {
  id: string;
  createdAt: string;
  email: string;
  status: string;
}

const commissionsSubject = new BehaviorSubject<Commission[]>([]);
const commissionsLoadingSubject = new BehaviorSubject<boolean>(false);

const clearError = () => addError('commission', undefined);

export const getCommissionSubject = (): BehaviorSubject<Commission[]> => commissionsSubject;
export const getCommissionLoadingSubject = (): BehaviorSubject<boolean> => commissionsLoadingSubject;

export const fetchCommissions = async (): Promise<void> => {
  commissionsLoadingSubject.next(true);

  const { data, error } = await fetchCommissionsApi();

  if (data) {
    clearError();
    commissionsSubject.next(data);
  }
  if (error) addError('commission', error.message);

  commissionsLoadingSubject.next(false);
};

export const startSync = async () => {
  const { data, error } = await postCommissionApi();
  if (data) {
    clearError();
    const comm = commissionsSubject.getValue();
    commissionsSubject.next([...comm, data]);
  }
  if (error) addError('commission', error.message);
};
