import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter
} from '@mui/x-data-grid-pro';
import SaveIcon from '@mui/icons-material/Save';
import { Button } from '@mui/material';
import { fileNameHelper } from '../../../utils/fileNameHelper';
import { useSnackbar } from '../../../Context/SnackbarContext';

export interface CustomToolbarProps {
  showSearch: boolean;
  title: string;
  setFilterButtonEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
  saveState: () => void;
}

export const CustomToolbar = ({ showSearch, title, setFilterButtonEl, saveState }: CustomToolbarProps) => {
  const { setSnack } = useSnackbar();

  return (
    <GridToolbarContainer
      data-testid="grid-toolbar"
      sx={{ padding: '1rem 0rem 3rem 1rem' }}>
      {showSearch && (
        <GridToolbarQuickFilter
          data-testid="grid-toolbar-search"
          data-cy="data-grid-search"
        />
      )}
      <div className={`mr-4 ${showSearch ? 'ml-auto' : ''}`}>
        <Button
          sx={{
            fontSize: '.8rem'
          }}
          onClick={() => {
            saveState();
            setSnack({ message: 'Your table settings were saved successfully', type: 'success', open: true });
          }}
          color="secondary"
          startIcon={<SaveIcon />}>
          Save Table
        </Button>
        <GridToolbarColumnsButton
          slotProps={{
            button: {
              color: 'secondary'
            }
          }}
        />
        <GridToolbarFilterButton
          slotProps={{
            button: {
              color: 'secondary'
            }
          }}
          ref={setFilterButtonEl}
        />
        <GridToolbarDensitySelector
          slotProps={{
            button: {
              color: 'secondary'
            }
          }}
        />
        <GridToolbarExport
          csvOptions={{
            fileName: `${fileNameHelper(title)}`
          }}
          slotProps={{
            button: {
              color: 'secondary'
            }
          }}
        />
      </div>
    </GridToolbarContainer>
  );
};
