import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  GridPreferences,
  User,
  fetchUsers,
  getCruxUsersLoadingSubject,
  getCruxUsersSubject,
  getDefaultPreferences,
  getUserSubject,
  updateUserDetail
} from '../../../../../store/User';
import { GridSingleSelectColDef } from '@mui/x-data-grid-pro';
import { DefaultUserGridColumnDefs } from '../../ColumnDefs/UserGridColumnDefs';
import { UpstackDataGrid } from '../../../../Compounds/UpstackDataGrid/UpstackDataGrid';
import { DetailsDrawer } from '../../../../Compounds/DetailsDrawer';
import { getMetaData } from '../../../../Compounds/DetailsDrawer/detailsDrawersHelpers';
import { getGridColumns } from '../../../../Compounds/UpstackDataGrid/helpers';

export const UserTab = () => {
  const [search] = useSearchParams();
  const detailId = search.get('detailId');
  const [usersLoading, setUsersLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<User[]>([]);
  const [userData, setUserData] = useState<User>();
  const [userColumns, setUserColumns] = useState<GridSingleSelectColDef[]>();
  const [gridSettings, setGridSettings] = useState<GridPreferences>(
    getDefaultPreferences()?.content['userGrid'] as GridPreferences
  );

  useEffect(() => {
    const loadingSubscription = getCruxUsersLoadingSubject().subscribe((loading) => setUsersLoading(loading));
    const usersSub = getCruxUsersSubject().subscribe((usrs) => setUsers(usrs));
    const userSub = getUserSubject().subscribe((usr) => {
      if (usr?.preferences?.content?.userGrid) {
        setGridSettings(usr.preferences.content.userGrid);
      }
    });

    fetchUsers();

    return () => {
      if (loadingSubscription) loadingSubscription.unsubscribe();
      if (usersSub) usersSub.unsubscribe();
      if (userSub) userSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!usersLoading && users.length > 0) {
      const currentData = users.find((t) => t.id.toString() === detailId);
      setUserData(currentData);
      updateUserDetail(currentData || ({} as User));
      setUserColumns(getGridColumns(users, DefaultUserGridColumnDefs, 'userGrid'));
    }
  }, [detailId, usersLoading, users.length]);

  return (
    <div data-testid="user-management">
      <UpstackDataGrid
        title="Users"
        rows={users}
        columns={userColumns}
        loadingData={usersLoading}
        page="userGrid"
        gridSettings={gridSettings}
        showSearch={true}
      />
      <DetailsDrawer
        data={userData}
        showDrawer={!!detailId}
        showButtons={false}
        {...getMetaData('user')}
      />
    </div>
  );
};
