import { ComponentName } from './detailsDrawersHelpers';
import { User } from '../../../store/User';
import { UserDetails } from '../../Pages/Admin/Tabs/User/UserDetails';

export interface DetailsDisplayProps {
  data: User;
  componentName?: ComponentName;
  setShow: (show: boolean) => void;
}

export const DetailsDisplay = ({ data, componentName, setShow }: DetailsDisplayProps) => {
  if (!componentName) return <div></div>;

  const getComponentByName = () => {
    switch (componentName) {
      case 'user':
        return <UserDetails />;
      default:
        return null;
    }
  };

  return <div className="overflow-auto">{getComponentByName()}</div>;
};
