import { HelmetProvider, Helmet } from 'react-helmet-async';
import Text from './Atoms/Text';
import { Icon } from './Atoms/Icon';
import { Tooltip } from '@mui/material';

export interface PageLayoutProps {
  pageTitle?: string;
  children?: React.ReactNode;
  pageActions?: React.ReactElement | React.ReactElement[];
  enableActions?: boolean;
  showTitle?: boolean;
  className?: string;
  width?: string;
  actionLocation?: string;
  dataCy?: string;
  dataTestId?: string;
  showSubTitle?: boolean;
  subTitle?: string;
  showLink?: boolean;
  link?: string;
}

export const PageLayout = ({
  pageTitle,
  children,
  pageActions,
  enableActions = true,
  className,
  width = 'w-11/12',
  actionLocation = 'top',
  showTitle = true,
  dataCy,
  dataTestId,
  showSubTitle = true,
  subTitle,
  showLink,
  link = ''
}: PageLayoutProps) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{`Crux ${pageTitle ? ` - ${pageTitle}` : ''}`}</title>
      </Helmet>
      <div
        data-cy={dataCy || ''}
        data-testid={dataTestId || ''}
        className={`h-screen ${width} page-layout m-auto basis-full ${!pageTitle ? 'mt-10' : ''} ${className || ''}`}>
        <div className={`flex flex-col w-full ${pageTitle ? 'mt-8' : ''}`}>
          {showSubTitle && (
            <Text
              color="grey8"
              size="sm"
              weight="medium">
              {subTitle}
            </Text>
          )}
          {showTitle && (
            <div className="flex items-center space-x-1">
              <Text
                color="grey8"
                size="xl"
                weight="medium">
                {pageTitle}
              </Text>
              {showLink && (
                <div className="pt-1">
                  <Tooltip
                    title="Open in SF"
                    placement="top">
                    <a
                      className="self-center"
                      data-testid="openInSalesforceButton"
                      href={link}
                      target="_blank"
                      rel="noreferrer">
                      <Icon type="external" />
                    </a>
                  </Tooltip>
                </div>
              )}
            </div>
          )}
          {enableActions && actionLocation === 'top' && <div className="ml-auto">{pageActions}</div>}
        </div>
        {children}
        {enableActions && actionLocation === 'bottom' && <div className="mt-5">{pageActions}</div>}
      </div>
    </HelmetProvider>
  );
};
