import ReactDOM from 'react-dom/client';
import './index.css';
import { createTheme, ThemeProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { OktaAppContainer } from './OktaAppContainer';
import { SnackbarProvider } from './Context/SnackbarContext';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2E01A4'
    },
    secondary: {
      main: '#7f7f7f'
    }
  },
  typography: {
    allVariants: {
      fontFamily: 'sans-serif',
      fontSize: '14px'
    }
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    // @ts-expect-error - this isn't in the TS because DataGird is not exported from `@mui/material`
    MuiDataGridPro: {
      styleOverrides: {
        row: {
          '&.Mui-selected': {
            backgroundColor: 'grey',
            color: 'yellow',
            '&:hover': {
              backgroundColor: 'purple'
            }
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'lightgrey'
          },
          '&:hover': {
            backgroundColor: 'lightgrey'
          }
        }
      }
    }
  }
});
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <BrowserRouter>
    <SnackbarProvider>
      <ThemeProvider theme={theme}>
        <OktaAppContainer />
      </ThemeProvider>
    </SnackbarProvider>
  </BrowserRouter>
);
