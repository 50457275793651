import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { Popover, IconButton } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Text from '../../Atoms/Text';
import { ComponentSpinner } from '../Loading/ComponentSpinner';
import {
  Commission,
  fetchCommissions,
  getCommissionSubject,
  getCommissionLoadingSubject
} from '../../../store/Commission';

export const CommissionPopover = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [commission, setCommission] = useState<Commission | undefined>(undefined);
  const [commissionLoading, setCommissionLoading] = useState<boolean>(false);

  useEffect(() => {
    const subscription = getCommissionSubject().subscribe((commissions) => {
      setCommission(commissions.at(-1));
    });

    const commissionLoadingSub = getCommissionLoadingSubject().subscribe((loadingState) =>
      setCommissionLoading(loadingState)
    );
    fetchCommissions();

    return () => {
      if (subscription) subscription.unsubscribe();
      if (commissionLoadingSub) commissionLoadingSub.unsubscribe();
    };
  }, []);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {commissionLoading && <ComponentSpinner label="loading..." />}
      {!commissionLoading && (
        <div className="rounded hover:bg-grey-2">
          <IconButton
            aria-label="info"
            className="hover:text-grey-6"
            data-testid="popover-info-button"
            onClick={handlePopoverOpen}
            sx={{ fontSize: '.86rem', padding: '', ':hover': { background: 'transparent' } }}>
            {commission?.createdAt && format(new Date(commission.createdAt), 'MMMM yyyy')} Run
            <MoreHorizIcon className="ml-2" />
          </IconButton>
        </div>
      )}
      {!commissionLoading && anchorEl !== null && (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}>
          <div className="p-4">
            <Text
              className="pb-1"
              color="grey5"
              size="sm"
              weight="normal">
              Commission Run
            </Text>
            <Text
              className="pb-1"
              size="sm"
              weight="normal">
              {commission?.createdAt && format(new Date(commission.createdAt), 'PPpp')}
            </Text>
            <Text
              className="pb-1"
              color="grey5"
              size="sm"
              weight="normal">
              Status
            </Text>
            <Text
              className="pb-1"
              size="sm"
              weight="normal">
              {commission?.status}
            </Text>
            <Text
              className="pb-1"
              color="grey5"
              size="sm"
              weight="normal">
              Requestor
            </Text>
            <Text
              className="pb-1"
              size="sm"
              weight="normal">
              {commission?.email}
            </Text>
          </div>
        </Popover>
      )}
    </>
  );
};

export default CommissionPopover;
