import { useParams } from 'react-router-dom';
import { PageLayout } from '../../PageLayout';
import { useEffect, useState } from 'react';
import { Customer, getCustomerById, getCustomerSubject } from '../../../store/Customer';
import { UpstackBreadcrumbs } from '../../Compounds/Breadcrumbs/Breadcrumbs';
import { DetailPanel, FieldLabelMap } from '../../Compounds/DetailPanel/DetailPanel';
import { TicketPanel } from './TicketPanel';
import { AdminPanel } from './AdminPanel';
import { isAdmin } from '../../../store/User';
import { TextSpan } from '../../Atoms/Text';

export const FIELD_LABEL_MAP: FieldLabelMap = {
  id: 'ID',
  name: 'Name',
  segment: 'Customer Segment',
  activeLocations: 'Active Locations',
  salesAgent: 'Sales Agent',
  assetCount: 'Assets',
  linkedAssetCount: 'Linked Asset Count',
  unlinkedAssetCount: 'Unlinked Asset Count',
  rpmAccounts: 'RPM Accounts',
  customerSuccessManager: 'Customer Success Manager',
  commissionsExperienceAnalyst: 'Commissions Experience Analyst'
};

export const CustomerDetail = () => {
  const [customerData, setCustomerData] = useState<Customer>({} as Customer);
  const { id } = useParams();
  const url = `${process.env.REACT_APP_SALESFORCE_INSTANCE_URL}/lightning/r/Asset/${id}/view`;

  const getData = async () => {
    if (id) {
      await getCustomerById(id);
    }
  };

  useEffect(() => {
    const custSub = getCustomerSubject().subscribe((cust) => setCustomerData(cust));

    getData();

    return () => {
      if (custSub) custSub.unsubscribe();
    };
  }, []);

  return (
    <PageLayout
      dataTestId="Customer-detail-page"
      subTitle="Customer"
      pageTitle={customerData?.name}
      showLink={true}
      link={url}>
      <UpstackBreadcrumbs
        crumbs={[
          { label: 'Customers', href: '/customer' },
          { label: `${customerData?.name}`, href: `/customer/${customerData?.id}` }
        ]}
      />
      <div className="w-full flex items-center mt-3 space-x-3">
        <div className="w-8/12 space-y-3">
          <DetailPanel
            fieldMap={FIELD_LABEL_MAP}
            data={customerData}
            canEdit={false}
          />

          {/* Linking component placeholder */}
          <div className="flex h-[20rem] border rounded-xl bg-slate-100">
            <TextSpan className="m-auto">Linking Component</TextSpan>
          </div>
        </div>
        <div className="w-4/12 self-start space-y-3">
          <TicketPanel />

          {/* comment component placeholder */}
          <div className="flex h-[10rem] border rounded-xl bg-slate-100">
            <TextSpan className="m-auto">Comments</TextSpan>
          </div>

          {isAdmin() && <AdminPanel data={customerData} />}
        </div>
      </div>
    </PageLayout>
  );
};
