import { Text } from '../../../../Atoms/Text';
import {
  fetchUsers,
  getUserDetailSubject,
  getUserLoadingSubject,
  updateUser,
  User,
  UserRole
} from '../../../../../store/User';
import { DetailViewCard } from '../../../../Compounds/CardWithTitle';
import { format } from 'date-fns';
import TextField from '@mui/material/TextField';
import { FormControl, MenuItem, Select } from '@mui/material';
import { DEFAULT_ADVANCE_BUTTON_STYLE } from '../../../../../utils/styleHelpers';
import { useEffect, useState } from 'react';
import { combineLatest } from 'rxjs';
import { ComponentSpinner } from '../../../../Compounds/Loading/ComponentSpinner';
import { useSnackbar } from '../../../../../Context/SnackbarContext';

export const EditUserForm = () => {
  const [userData, setUserData] = useState<User>({} as User);
  const [usersLoading, setUsersLoading] = useState<boolean>(false);
  const { setSnack } = useSnackbar();

  const handleChange = (name: keyof User, value: string) => {
    const updatedUserData = {
      ...userData,
      [name]: value.trim()
    };
    setUserData(updatedUserData as User);
  };

  const handleSave = async () => {
    if (userData) {
      await updateUser(userData);
      setSnack({ message: 'User saved.', type: 'success', open: true });
      fetchUsers();
    }
  };

  useEffect(() => {
    const dataSubscription = combineLatest([getUserLoadingSubject(), getUserDetailSubject()]).subscribe(
      ([usersLoading, userUpdate]) => {
        setUsersLoading(usersLoading);
        if (userUpdate) setUserData(userUpdate);
      }
    );
    return () => {
      if (dataSubscription) dataSubscription.unsubscribe();
    };
  }, []);

  return (
    <DetailViewCard
      key="admin"
      title="Details">
      {usersLoading ? (
        <div className="flex h-40 m-auto">
          <ComponentSpinner label="loading..." />
        </div>
      ) : (
        <form onSubmit={(e: React.FormEvent<HTMLFormElement>) => e.preventDefault()}>
          <div
            className="flex mb-8"
            data-testid="detail-row-id">
            <Text
              className="font-sans text-grey-4 w-3/5"
              size="sm14">
              User ID
            </Text>
            <Text
              className="font-sans font-normal w-full"
              size="sm">
              {userData?.id}
            </Text>
          </div>
          <div
            className="flex mb-8"
            data-testid="detail-row-first_name">
            <Text
              className="font-sans text-grey-4 w-3/5"
              size="sm14">
              First Name
            </Text>
            <div className="w-full">
              <TextField
                disabled
                data-testid="first-name-input"
                data-cy="first-name"
                sx={{ width: '100%' }}
                value={userData?.firstName || ''}
                onChange={(e) => handleChange('firstName', e?.target?.value)}
                size="small"
              />
            </div>
          </div>
          <div
            className="flex mb-8"
            data-testid="detail-row-last_name">
            <Text
              className="font-sans text-grey-4 w-3/5"
              size="sm14">
              Last Name
            </Text>
            <div className="w-full">
              <TextField
                disabled
                data-testid="last-name-input"
                data-cy="last-name"
                sx={{ width: '100%' }}
                value={userData?.lastName || ''}
                onChange={(e) => handleChange('lastName', e?.target?.value)}
                size="small"
              />
            </div>
          </div>
          <div
            className="flex mb-8"
            data-testid="detail-row-email">
            <Text
              className="font-sans text-grey-4 w-3/5"
              size="sm14">
              Email
            </Text>
            <div className="w-full">
              <TextField
                disabled
                data-testid="email-input"
                data-cy="email"
                sx={{ width: '100%' }}
                value={userData?.email || ''}
                onChange={(e) => handleChange('email', e?.target?.value)}
                size="small"
              />
            </div>
          </div>
          <div
            className="flex mb-8"
            data-testid="detail-row-email">
            <Text
              className="font-sans text-grey-4 w-3/5"
              size="sm14">
              Role
            </Text>
            <FormControl
              sx={{ width: '100%' }}
              size="small">
              <Select
                displayEmpty
                data-testid="role-select"
                id="source-tab-select"
                inputProps={{ 'aria-label': 'Without label' }}
                value={userData?.role || ''}
                onChange={(e) => handleChange('role', e?.target?.value)}>
                <MenuItem
                  key="observer"
                  value={UserRole.OBSERVER}>
                  Observer
                </MenuItem>
                <MenuItem
                  key="Analyst"
                  value={UserRole.ANALYST}>
                  Analyst
                </MenuItem>
                <MenuItem
                  key="admin"
                  value={UserRole.ADMIN}>
                  Admin
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div
            className="flex mb-8"
            data-testid="detail-row-updated_at">
            <Text
              className="font-sans text-grey-4 w-3/5"
              size="sm14">
              Last Login
            </Text>
            <Text
              className="font-sans font-normal w-full"
              size="sm">
              {userData?.updatedAt ? format(new Date(userData?.updatedAt), 'PPpp') : ''}
            </Text>
          </div>
          <div className="flex w-full">
            <button
              className={`${DEFAULT_ADVANCE_BUTTON_STYLE} ml-auto`}
              onClick={handleSave}>
              Save
            </button>
          </div>
        </form>
      )}
    </DetailViewCard>
  );
};
