import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { addError } from './Error';
import { getSearchResults } from '../Api/Search/searchApi';

export enum EntityType {
  CUSTOMER = 'customer',
  ACCOUNT = 'rpm_account',
  ASSET = 'asset'
}

export interface SearchResult {
  id: string;
  title: string;
  source: string;
  rank: number;
  matched_value: string;
}

export interface FilterSearchDto {
  query: string;
  entityTypes?: string;
  limit?: number;
}

const searchResultsSubject = new BehaviorSubject<SearchResult[]>([]);
const searchResultsLoadingSubject = new BehaviorSubject<boolean>(false);

export const getSearchResultsSubject = (): BehaviorSubject<SearchResult[]> => searchResultsSubject;
export const getSearchResultsLoadingSubject = (): BehaviorSubject<boolean> => searchResultsLoadingSubject;

export const fetchSearchResults = async (filters: FilterSearchDto) => {
  searchResultsLoadingSubject.next(true);

  const { data, error } = await getSearchResults(filters);

  if (data) searchResultsSubject.next(data);
  if (error) addError('search', error.message);

  searchResultsLoadingSubject.next(false);
};
