import { MutableRefObject } from 'react';
import { BehaviorSubject } from 'rxjs';
import { GridApiPro } from '@mui/x-data-grid-pro';

export interface ApiFilter {
  page?: number;
  pageSize?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: string | number | boolean | undefined;
}

export interface PaginatedResponse<T> {
  data: T[];
  page: number;
  pageSize: number;
  totalCount: number;
  totalPages: number;
}

export interface AdminState {
  selectedTab: number;
}

export interface GeneralState {
  admin: AdminState;
  gridRefs: { [page: string]: MutableRefObject<GridApiPro> };
}

export interface UseGeneralStoreReturn {
  updateState: (data: Partial<GeneralState>) => void;
  clear: () => void;
  value: GeneralState;
}

export const defaultState: GeneralState = {
  admin: {
    selectedTab: Number(localStorage.getItem('adminTab')) || 0
  },
  gridRefs: {}
};

const subject = new BehaviorSubject<GeneralState>(defaultState);
export const getState = () => subject.getValue();
export const getGeneralStoreSubject = (): BehaviorSubject<GeneralState> => subject;

export const updateState = (update: Partial<GeneralState>) => {
  const currentState = { ...subject.getValue() };
  subject.next({ ...currentState, ...update });

  if (update?.admin && 'selectedTab' in update.admin) {
    localStorage.setItem('adminTab', String(update?.admin?.selectedTab));
  }
};

export const updateGridRef = (page: string, ref: MutableRefObject<GridApiPro>) => {
  const currentStateUpdate = { ...subject.getValue() };

  currentStateUpdate.gridRefs = {
    ...currentStateUpdate.gridRefs,
    [page]: ref
  };

  subject.next(currentStateUpdate);
};

export const clear = () => subject.next(defaultState);
