import { useState } from 'react';
import { PageLayout } from '../../PageLayout';
import { useTheme, Tabs, Tab } from '@mui/material';
import { TabPanel } from '../../Compounds/TabPanel/TabPanel';
import { UserTab } from './Tabs/User/UserTab';
import { SyncTab } from './Tabs/RPMSync/SyncTab';

export const Admin = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const theme = useTheme();

  const tabs = [
    {
      name: 'Users',
      component: <UserTab />
    },
    {
      name: 'RPM Sync',
      component: <SyncTab />
    }
  ];

  const handleTabChange = (index: number) => setSelectedTab(index);

  return (
    <PageLayout
      dataTestId="admin-page"
      dataCy="admin-page-card"
      pageTitle="Admin">
      <>
        <Tabs
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '.5rem',
            backgroundColor: 'rgb(247 247 247)',
            borderBottom: '1px solid rgb(229 231 235)',
            borderTop: '1px solid rgb(229 231 235)'
          }}
          TabIndicatorProps={{
            sx: {
              backgroundColor: theme.palette.primary.main,
              height: selectedTab <= tabs.length - 1 ? 2 : 0,
              borderRadius: 1
            }
          }}
          value={selectedTab}
          onChange={(_e, index) => handleTabChange(index)}>
          {tabs.map(({ name }, index) => (
            <Tab
              data-cy={`tab-link-${name}`}
              sx={{ textTransform: 'none', fontSize: '.8rem', color: 'black' }}
              key={index}
              label={name}
            />
          ))}
        </Tabs>
        {tabs.map(({ component }, index) => (
          <TabPanel
            key={`${index}`}
            index={index}
            value={selectedTab}>
            {component}
          </TabPanel>
        ))}
      </>
    </PageLayout>
  );
};
