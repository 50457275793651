import React from 'react';
import { NavBarTabs } from './NavBarTabs';

export const NavBar = () => {
  return (
    <nav
      data-testid="nav-bar-container"
      className="flex py-4 px-4 border-gray-200 border-b">
      <NavBarTabs />
    </nav>
  );
};
