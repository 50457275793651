import { GridCellParams, GridSingleSelectColDef } from '@mui/x-data-grid-pro';
import { standardOperators } from '../../Compounds/UpstackDataGrid/helpers';
import { DetailCTARenderer } from '../../Compounds/UpstackDataGrid/CellRenderers/DetailCTARenderer';

export const DefaultAssetGridColumnDefs: GridSingleSelectColDef[] = [
  {
    field: 'details',
    headerName: '',
    sortable: false,
    width: 65,
    filterable: false,
    type: 'singleSelect',
    valueOptions: [],
    renderCell: (params: GridCellParams) => (
      <DetailCTARenderer
        data={params}
        value={''}
        valueFormatted={''}
      />
    ),
    hideable: false,
    disableReorder: true
  },
  {
    field: 'sf_id',
    headerName: 'SFID',
    sortable: true,
    filterable: true,
    flex: 1,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'id',
    headerName: 'ID',
    sortable: true,
    filterable: true,
    flex: 1,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'accountId',
    headerName: 'Account Id',
    sortable: true,
    filterable: true,
    flex: 1,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'name',
    headerName: 'Name',
    sortable: true,
    filterable: true,
    flex: 1,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'supplierId',
    headerName: 'Supplier ID',
    sortable: true,
    filterable: true,
    flex: 1,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  }
];
