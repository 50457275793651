import { EntityType, FilterSearchDto, SearchResult } from '../../store/Search';
import { buildQueryURL } from '../../utils/helpers';
import { callExternalApi } from '../callExternalApi';

export const getSearchResults = (filters: FilterSearchDto) => {
  const mergedFilters: FilterSearchDto = {
    limit: 10,
    entityTypes: [EntityType.CUSTOMER, EntityType.ASSET, EntityType.ACCOUNT].join(','),
    ...filters
  };

  return callExternalApi<SearchResult[]>({
    url: buildQueryURL(mergedFilters, 'search'),
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  });
};
