import { User } from '../../../store/User';
import { Text } from '../../Atoms/Text';

export type ComponentName = 'user';
export interface DetailsMeta {
  title?: string;
  subtitleKey?: string;
  titleKey?: string;
  componentName?: ComponentName;
  idKey?: string;
  error?: string;
}

export const getComponentName = (pathname: string) => {
  if (/\/user/.test(pathname)) return 'user';
};

export const getMetaData = (name: ComponentName): DetailsMeta => {
  switch (name) {
    case 'user':
      return {
        title: 'User Details',
        subtitleKey: 'lastName',
        componentName: 'user'
      };
  }
};

export const subTitle = (componentName: ComponentName, data: User, subtitleKey: string) => {
  switch (componentName) {
    case 'user': {
      return (
        <Text size="lg">
          {`${data['firstName' as keyof typeof data] || ''} ${data['lastName' as keyof typeof data] || ''}`}
        </Text>
      );
    }
  }
};

export const getTitle = (data: User, titleKey?: string) => {
  return titleKey ? String(data[titleKey as keyof typeof data]) : '';
};
