import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { TextField, InputAdornment, Paper, List, ListItem, ListItemText, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { fetchSearchResults, getSearchResultsSubject, SearchResult } from '../../../store/Search';
import { Icon, IconType } from '../../Atoms/Icon';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';

export const ICON_MAP: { [key: string]: IconType } = {
  customer: 'customerSmall',
  asset: 'assetSmall',
  rpm_account: 'rpmAccountSmall'
};

export const SearchBox: FC = () => {
  const [query, setQuery] = useState<string>('');
  const [results, setResults] = useState<SearchResult[]>([]);
  const [showResults, setShowResults] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const handleSearch = useCallback(
    debounce(async () => {
      await fetchSearchResults({ query });
      setShowResults(true);
    }, 500),
    []
  );

  const handleSelectResult = (result: SearchResult): void => {
    navigate(`/${result.source}/${result.id}`);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
      setShowResults(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    const searchResultsSub = getSearchResultsSubject().subscribe((res) => setResults(res));

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      if (searchResultsSub) searchResultsSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (query.length >= 5) handleSearch();
    else setResults([]);
  }, [query]);

  return (
    <div
      data-testid="search-box"
      className="relative"
      ref={wrapperRef}>
      <TextField
        data-testid="search-input"
        type="search"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        variant="outlined"
        fullWidth
        name="search"
        size="small"
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowResults(!showResults)}>
                  <ArrowDropDownIcon />
                </IconButton>
              </InputAdornment>
            )
          }
        }}
        sx={{ width: '16rem' }}
        onFocus={() => setShowResults(true)}
      />
      {showResults && (
        <Paper
          style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            right: 0,
            zIndex: 1,
            marginTop: '.2rem',
            maxHeight: '10rem',
            overflowY: 'auto'
          }}
          elevation={3}>
          <List data-testid="search-result-list">
            {!!results.length &&
              results.map((result) => (
                <ListItem
                  title={result.source}
                  sx={{ ':hover': { cursor: 'pointer' }, gap: 2, alignItems: 'center' }}
                  key={result.id}
                  onClick={() => handleSelectResult(result)}>
                  <Icon
                    className="p-2"
                    type={ICON_MAP[result.source]}
                  />
                  <ListItemText primary={result.title} />
                </ListItem>
              ))}
            {!results.length && (
              <ListItem
                data-testid="no-results-found"
                sx={{ textAlign: 'center' }}
                key="none">
                <ListItemText primary={query.length < 5 ? 'Please type five characters to search' : 'No results'} />
              </ListItem>
            )}
          </List>
        </Paper>
      )}
    </div>
  );
};
