import { useState } from 'react';
import { Text } from '../../Atoms/Text';
import { TextField } from '@mui/material';

export interface LabelValueFieldParams {
  label: string;
  value: string | number;
  save?: () => void;
  canEdit?: boolean;
}

export const LabelValueField = ({ label, value, save, canEdit = false }: LabelValueFieldParams) => {
  const [mode, setMode] = useState<'read' | 'edit'>('read');

  const handleBlur = () => {
    setMode('read');
    if (save) save();
  };

  return (
    <div
      style={{ flex: '1 1 calc(100% / var(--items-per-row))' }}
      className="flex flex-col mb-1 p-4"
      data-testid="labelValueField-container">
      {mode === 'read' && (
        <>
          <Text
            dataTestId={`${label}-label-field`}
            size="sm14"
            color="grey5">
            {label}
          </Text>
          <Text
            className={`${canEdit && 'cursor-pointer'}`}
            dataTestId={`${label}-read-only-field`}
            onClick={() => canEdit && setMode(mode === 'read' ? 'edit' : 'read')}
            size="sm14"
            color="grey7"
            weight="medium">
            {value}
          </Text>
        </>
      )}
      {mode === 'edit' && (
        <TextField
          data-testid={`${label}-edit-field`}
          label={label}
          variant="outlined"
          value={value}
          onBlur={handleBlur}
        />
      )}
    </div>
  );
};
