import { GridCellParams, GridSingleSelectColDef } from '@mui/x-data-grid-pro';
import { format } from 'date-fns';
import { DetailCTARenderer } from '../../../Compounds/UpstackDataGrid/CellRenderers/DetailCTARenderer';
import { allOperators, standardOperators } from '../../../Compounds/UpstackDataGrid/helpers';

export const DefaultUserGridColumnDefs: GridSingleSelectColDef[] = [
  {
    field: 'details',
    headerName: '',
    sortable: false,
    width: 65,
    filterable: false,
    type: 'singleSelect',
    valueOptions: [],
    renderCell: (params: GridCellParams) => (
      <DetailCTARenderer
        data={params}
        value={''}
        valueFormatted={''}
      />
    ),
    hideable: false,
    disableReorder: true
  },
  {
    field: 'firstName',
    headerName: 'First Name',
    sortable: true,
    filterable: true,
    flex: 1,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'lastName',
    headerName: 'Last Name',
    sortable: true,
    filterable: true,
    flex: 1,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'email',
    headerName: 'Email',
    sortable: true,
    filterable: true,
    flex: 1,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'role',
    headerName: 'Role',
    filterable: true,
    flex: 1,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'lastLogin',
    headerName: 'Last Login',
    sortable: true,
    valueFormatter: (value) => {
      return value ? format(value, 'PPpp') : '';
    },
    filterable: true,
    flex: 1,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: allOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    sortable: true,
    valueFormatter: (value) => {
      return value ? format(value, 'PPpp') : '';
    },
    filterable: true,
    flex: 1,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: allOperators(),
    description: 'Drag to move column'
  }
];
