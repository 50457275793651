import { FunctionComponent } from 'react';
import { TextColor } from '../../Atoms/Text';
import DropdownMenu from '../Menu/DropdownMenu';
import { IconType } from '../../Atoms/Icon';
import { useOktaAuth } from '@okta/okta-react';
import { getCurrentUser } from '../../../store/User';
import { CommissionPopover } from '../../Compounds/CommissionPopover/CommissionPopover';
import { SearchBox } from '../Search/Search';

export const NavBarTabs: FunctionComponent = () => {
  const { oktaAuth } = useOktaAuth();

  const menuOptions = [
    {
      title: 'Log Out',
      onClick: async () => {
        await oktaAuth.signOut();
      },
      fontColor: 'grey6' as TextColor,
      icon: 'logoutSmall' as IconType
    }
  ];

  return (
    <div className="flex w-full items-center">
      <div className="flex ml-3 mr-auto justify-start space-x-3 items-center">
        <SearchBox />
        <CommissionPopover />
      </div>
      <div className="flex ml-auto justify-end space-x-3 items-center">
        <DropdownMenu
          icon="account"
          buttonText={`${getCurrentUser()?.firstName} ${getCurrentUser()?.lastName}`}
          options={menuOptions}
          slotProps={{
            paper: {
              style: {
                width: 150
              }
            }
          }}
        />
      </div>
    </div>
  );
};
