import { IconType } from '../../Atoms/Icon';
import { CardWithTitle } from '../CardWithTitle';

export interface PageCardProps {
  title: string;
  children: JSX.Element | JSX.Element[];
  headerContent?: JSX.Element | JSX.Element[];
  cardActions?: JSX.Element | JSX.Element[];
  titleIcon?: IconType;
  className?: string;
  childrenWrapperClass?: string;
  enableCollapse?: boolean;
  enableShadow?: boolean;
  collapsed?: boolean;
  dataCy?: string;
  dataTestId?: string;
}

export function PageCard({
  title,
  children,
  headerContent,
  cardActions,
  titleIcon,
  className,
  childrenWrapperClass,
  enableCollapse = true,
  enableShadow = false,
  collapsed = false,
  dataCy,
  dataTestId
}: PageCardProps) {
  return (
    <CardWithTitle
      title={title}
      enableShadow={enableShadow}
      enableCollapse={enableCollapse}
      headerContent={headerContent}
      cardActions={cardActions}
      titleIcon={titleIcon}
      className={className}
      collapsed={collapsed}
      dataCy={dataCy}
      dataTestId={dataTestId}
      childrenWrapperClass={childrenWrapperClass}>
      {children}
    </CardWithTitle>
  );
}
