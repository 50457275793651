import { DEFAULT_ADVANCE_BUTTON_STYLE, DEFAULT_CANCEL_BUTTON_STYLE } from '../../../utils/styleHelpers';
import { Text, SrOnlyText } from '../../Atoms/Text';

const DEFAULT_DRAWER_CLASSES = [
  'bg-white',
  'border-l',
  'border-grey-2',
  'fixed',
  'z-50',
  'flex',
  'flex-col',
  'top-0',
  'right-0',
  'h-screen',
  'lg:w-2/5',
  'w-full',
  'lg:max-w-[50rem]',
  'p-6'
].join(' ');

export interface DrawerProps {
  children: JSX.Element | JSX.Element[];
  show: boolean;
  setShow?: (show: boolean) => void;
  title?: string;
  subtitle?: JSX.Element;
  description?: string;
  className?: string;
  style?: object;
  link?: string;
  state?: object;
  componentName?: string;
  saveAction?: () => void;
  showButtons?: boolean;
}

export const Drawer = ({
  show,
  setShow,
  title,
  subtitle,
  description,
  className,
  children,
  link = '',
  componentName,
  saveAction,
  showButtons,
  ...props
}: DrawerProps) => {
  if (!show) return <></>;
  return (
    <div
      {...props}
      className={`${DEFAULT_DRAWER_CLASSES} ${className}`}>
      {setShow && (
        <button
          data-cy="close-drawer-button"
          className="cancel-button absolute self-end border p-4 rounded-lg"
          onClick={() => setShow(false)}>
          <SrOnlyText dataTestId="cancel_button">hide drawer</SrOnlyText>
        </button>
      )}
      <Text
        dataCy="detail-drawer-title"
        className=""
        color="grey5">
        {title}
      </Text>
      {subtitle}
      <Text
        className="mb-[15px]"
        size="sm">
        {description}
      </Text>
      {children}
      <div className="flex items-center w-full h-[6%] pt-6">
        {showButtons && (
          <div className="absolute right-[40px]">
            {setShow && (
              <button
                className={`${DEFAULT_CANCEL_BUTTON_STYLE} mr-[15px]`}
                onClick={() => setShow(false)}>
                Cancel
              </button>
            )}
            <button
              className={DEFAULT_ADVANCE_BUTTON_STYLE}
              onClick={saveAction}>
              Save
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
