import { useEffect, useState } from 'react';
import { PageCard } from '../../../../Compounds/CardWithTitle/PageCard';
import {
  Commission,
  fetchCommissions,
  getCommissionLoadingSubject,
  getCommissionSubject,
  startSync
} from '../../../../../store/Commission';
import { GridSingleSelectColDef } from '@mui/x-data-grid-pro';
import { getDefaultPreferences, getUserSubject, GridPreferences } from '../../../../../store/User';
import { getGridColumns } from '../../../../Compounds/UpstackDataGrid/helpers';
import { DefaultCommissionGridColumnDefs } from '../../ColumnDefs/CommissionGridColumnDefs';
import { TextSpan } from '../../../../Atoms/Text';
import { DEFAULT_ADVANCE_BUTTON_STYLE } from '../../../../../utils/styleHelpers';
import { UpstackDataGrid } from '../../../../Compounds/UpstackDataGrid/UpstackDataGrid';
import { useSnackbar } from '../../../../../Context/SnackbarContext';

export const SyncTab = () => {
  const [commissions, setCommissions] = useState<Commission[]>([]);
  const [commissionColumns, setCommissionColumns] = useState<GridSingleSelectColDef[]>(DefaultCommissionGridColumnDefs);
  const [commissionLoading, setCommissionLoading] = useState<boolean>(false);
  const [gridSettings, setGridSettings] = useState<GridPreferences>(
    getDefaultPreferences().content['commissionGrid'] as GridPreferences
  );
  const { setSnack } = useSnackbar();

  useEffect(() => {
    const subscription = getCommissionSubject().subscribe((commissions) => {
      setCommissions(commissions);
      setCommissionColumns(getGridColumns(commissions, DefaultCommissionGridColumnDefs, 'commissionGrid'));
    });

    const userSub = getUserSubject().subscribe((usr) => {
      if (usr?.preferences?.content?.commissionGrid) {
        setGridSettings(usr.preferences.content.userGrid);
      }
    });
    const commissionLoadingSub = getCommissionLoadingSubject().subscribe((loadingState) =>
      setCommissionLoading(loadingState)
    );
    fetchCommissions();

    return () => {
      if (subscription) subscription.unsubscribe();
      if (userSub) userSub.unsubscribe();
      if (commissionLoadingSub) commissionLoadingSub.unsubscribe();
    };
  }, []);

  const pageActions = () => (
    <TextSpan className="flex items-center space-x-2">
      <button
        data-cy="start-sync-button"
        className={DEFAULT_ADVANCE_BUTTON_STYLE}
        onClick={() => {
          startSync();
          setSnack({ message: 'Sync started', type: 'success', open: true });
        }}>
        Sync Now
      </button>
    </TextSpan>
  );

  return (
    <PageCard
      dataTestId="rpm-sync-card"
      dataCy="rpm-sync-card"
      childrenWrapperClass="min-h-80 max-w-[60vw] min-w-full"
      title="RPM Sync"
      cardActions={pageActions()}>
      <UpstackDataGrid
        title="Commissions"
        rows={commissions}
        columns={commissionColumns}
        loadingData={commissionLoading}
        page="commissionGrid"
        gridSettings={gridSettings}
        showSearch={true}
      />
    </PageCard>
  );
};
