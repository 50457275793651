import { useSearchParams } from 'react-router-dom';
import { DetailsDisplay } from './DetailsDisplay';
import { useLocation } from 'react-router-dom';
import { ComponentName, getTitle, subTitle } from './detailsDrawersHelpers';
import { User } from '../../../store/User';
import { Drawer } from '../Drawer/Drawer';

export type DetailsDrawerProps = {
  title?: string;
  subtitleKey?: string;
  titleKey?: string;
  componentName?: ComponentName;
  data?: User;
  showDrawer: boolean;
  link?: string;
  saveAction?: () => void;
  showButtons?: boolean;
};

export function DetailsDrawer({
  title,
  subtitleKey,
  titleKey,
  componentName,
  data,
  showDrawer,
  link = '',
  saveAction,
  showButtons
}: DetailsDrawerProps) {
  const [params, setParams] = useSearchParams();

  const handleDetailClick = () => {
    if (params.has('detailId')) params.delete('detailId');
    setParams(params, { replace: true });
  };

  const location = useLocation();
  const state = {
    data,
    prevpath: location.pathname,
    title,
    componentName
  };

  if (!componentName || !data) return <div></div>;

  return (
    <Drawer
      data-testid="details-drawer"
      show={showDrawer}
      title={title || getTitle(data, titleKey)}
      subtitle={subTitle(componentName, data, subtitleKey || '')}
      setShow={handleDetailClick}
      saveAction={saveAction}
      showButtons={showButtons}
      link={link}
      componentName={componentName}
      state={state}>
      <DetailsDisplay
        data={data}
        componentName={componentName}
        setShow={handleDetailClick}
      />
    </Drawer>
  );
}
