import { Icon } from '../../Atoms/Icon';
import { TextSpan } from '../../Atoms/Text';
import { PageCard } from '../../Compounds/CardWithTitle';

export const TicketPanel = () => {
  const handleAddTicket = () => {
    console.log('handleAddTicket');
  };

  const cardActions = () => {
    return (
      <button
        disabled
        className="flex items-center space-x-2"
        onClick={handleAddTicket}
        data-cy="new-user">
        <Icon
          type="plus"
          className="p-2"
        />
        <TextSpan
          color="indigo"
          size="sm14">
          Add Ticket
        </TextSpan>
      </button>
    );
  };

  return (
    <PageCard
      dataTestId="ticket-panel"
      childrenWrapperClass="bg-slate-100 rounded-xl"
      cardActions={cardActions()}
      title="Tickets">
      <div className="w-full text-">Not yet implemented</div>
    </PageCard>
  );
};
