import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom';
import Text from '../../Atoms/Text';

export interface Crumb {
  label: string;
  href: string;
}

export interface UpstackBreadcrumbsParams {
  crumbs: Crumb[];
}

export const UpstackBreadcrumbs = ({ crumbs }: UpstackBreadcrumbsParams) => {
  return (
    <div
      className="mt-1"
      data-testid="breadcrumb-container"
      role="presentation">
      <Breadcrumbs aria-label="breadcrumb">
        {crumbs.map((crumb, i) => (
          <Link
            key={i}
            to={crumb.href}>
            <Text
              size="sm"
              color="indigo">
              {crumb.label}
            </Text>
          </Link>
        ))}
      </Breadcrumbs>
    </div>
  );
};
