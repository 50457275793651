import { FilterAssetDto } from '../store/Asset';
import { FilterSearchDto } from '../store/Search';

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const usaCurrency = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

export function formatInfo(obj: string) {
  return <span style={{ whiteSpace: 'pre-wrap' }}>{obj}</span>;
}

export const getNestedValue = (obj: object, keyStr: string) => {
  const nestedVal = keyStr.split('.').reduce((o: object, i: string) => o?.[i as keyof typeof o], obj);
  return typeof nestedVal === 'string' ? nestedVal : '';
};

export const truncateStr = (val: string, maxLength = 20) =>
  val.length > maxLength ? `${val.substring(0, maxLength)}...` : val;

export const objectNotEmpty = (obj: object) => !!Object.keys(obj).length;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getObjValidity = (data: any, requiredKeys: string[]) => {
  const missingProperties = Object.keys(data)
    .filter((key) => requiredKeys.includes(key) && !data[key])
    .map((key) => key);

  return { errors: missingProperties, valid: missingProperties.length === 0 };
};

export const capitalizeText = (text: string): string => text[0].toUpperCase() + text.slice(1).toLowerCase();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const arrayMove = (arr: any[], from: number, to: number) => {
  const element = arr[from];
  arr.splice(from, 1);
  arr.splice(to, 0, element);
};

export const formatCamelCaseKey = (key: string) => {
  return key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());
};

export const buildQueryURL = (filters: FilterSearchDto | FilterAssetDto, baseUrl: string): string => {
  let url = `${process.env.REACT_APP_API_SERVER_URL}/${baseUrl}?`;
  const urlParams = new URLSearchParams();

  for (const [key, value] of Object.entries(filters)) {
    if (value !== undefined && value !== null) urlParams.append(key, value.toString());
  }

  if (urlParams) url += urlParams.toString();

  return url;
};
