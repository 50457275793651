import { useEffect, useState } from 'react';
import './App.css';
import AppRoutes from './components/AppRoutes';
import { NavBar } from './components/Compounds/Navigation/NavBar';
import { SideNavBar } from './components/Compounds/Navigation/SideNavBar';
import { getUserLoadingSubject, useCruxUser } from './store/User';
import { LicenseInfo } from '@mui/x-license';
import { LoadingIconPage } from './components/Compounds/Loading/loadingIcon';
import { useErrorStore } from './store/Error';
import { useOktaAuth } from '@okta/okta-react';

export const App = () => {
  useCruxUser();
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE || '');
  const [userLoading, setUserLoading] = useState(false);
  const { errorMessageSubscription } = useErrorStore();
  const { oktaAuth } = useOktaAuth();
  const showLoading: boolean = [userLoading].some((loadingState) => loadingState);

  useEffect(() => {
    const loadingSubscription = getUserLoadingSubject().subscribe((loading) => setUserLoading(loading));
    const errorSubscription = errorMessageSubscription.subscribe(async (errors) => {
      if (!errors) return;
      if (errors.includes('401')) {
        try {
          await oktaAuth.signOut();
          oktaAuth.signInWithRedirect();
        } catch (err) {
          console.error('Error', err);
        }
      }
    });

    return () => {
      if (loadingSubscription) loadingSubscription.unsubscribe();
      if (errorSubscription) errorSubscription.unsubscribe();
    };
  }, []);

  return (
    <div
      data-testid="app-container"
      className="min-h-screen flex">
      {showLoading && <LoadingIconPage />}
      {!showLoading && (
        <>
          <SideNavBar />
          <div
            data-testid="app-container"
            className="flex flex-col basis-full main-container">
            <NavBar />
            <AppRoutes />
          </div>
        </>
      )}
    </div>
  );
};
