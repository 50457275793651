import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import oktaConfig from './OktaConfig';
import { useNavigate } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import { App } from './App';

export const OktaAppContainer = () => {
  const oktaAuth = new OktaAuth({ ...oktaConfig, devMode: true });
  const navigate = useNavigate();

  const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
    const relativeUrl = toRelativeUrl(originalUri || '/', window.location.origin);
    navigate(relativeUrl);
  };

  return (
    <Security
      onAuthRequired={() => window.location.assign('/login')}
      oktaAuth={oktaAuth}
      restoreOriginalUri={restoreOriginalUri}>
      <App />
    </Security>
  );
};
