import { CSSProperties, useEffect, useState } from 'react';
import { Customer } from '../../../store/Customer';
import { LabelValueField } from './LabelValueField';

export interface FieldLabelMap {
  [key: string]: string;
}

export interface Detail {
  label: string;
  value: string | number;
}

export interface DetailPanelParams {
  data: Customer;
  canEdit: boolean;
  fieldMap: FieldLabelMap;
}

export const DetailPanel = ({ data, canEdit, fieldMap }: DetailPanelParams) => {
  const [detailData, setDetailData] = useState<Detail[]>([]);

  const formatData = () => {
    const details = Object.keys(data).reduce<Detail[]>((acc, k) => {
      const val = data[k as keyof typeof data] || '';
      if (!/^\s*$/.test(String(val)) && val && fieldMap[k]) acc.push({ label: fieldMap[k], value: val });
      return acc;
    }, []);

    setDetailData(details);
  };

  useEffect(() => formatData(), [data]);

  return (
    <div
      style={{ '--items-per-row': 4 } as CSSProperties}
      className="flex flex-row flex-wrap border rounded-xl px-2 pr-6 py-1 min-h-[25rem]"
      data-testid="detail-panel-container">
      {detailData.map((d) => (
        <LabelValueField
          key={`${d.label}-${d.value}`}
          label={d.label}
          value={d.value}
          canEdit={canEdit}
        />
      ))}
    </div>
  );
};
