import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { getAsset, listAssets } from '../Api/Asset/AssetApi';
import { addError } from './Error';
import { ApiFilter, PaginatedResponse } from './GeneralStore';

export interface Asset {
  id: string;
  sf_id: string;
  accountId: string | null;
  name: string;
  supplierId: string;
}

export interface FilterAssetDto extends ApiFilter {
  sf_id?: string;
  accountId?: string;
  name?: string;
  supplierId?: string;
}

const assetSubject = new BehaviorSubject<Asset>({} as Asset);
const cruxAssetsSubject = new BehaviorSubject<PaginatedResponse<Asset>>({} as PaginatedResponse<Asset>);
const assetLoadingSubject = new BehaviorSubject<boolean>(false);
const cruxAssetsLoadingSubject = new BehaviorSubject<boolean>(false);

const clearError = () => addError('asset', undefined);

export const getAssetSubject = (): BehaviorSubject<Asset> => assetSubject;
export const getCruxAssetsSubject = (): BehaviorSubject<PaginatedResponse<Asset>> => cruxAssetsSubject;
export const getCruxAssetsLoadingSubject = (): BehaviorSubject<boolean> => cruxAssetsLoadingSubject;
export const getAssetLoadingSubject = (): BehaviorSubject<boolean> => assetLoadingSubject;

export const getAssetById = async (id: string) => {
  const asset = cruxAssetsSubject?.getValue()?.data?.find((a) => a?.id === id);
  if (asset) {
    assetSubject.next(asset);
    return;
  }

  if (!asset) {
    await fetchAsset(id);
  }
};

export const fetchAsset = async (id: string) => {
  assetLoadingSubject.next(true);

  const { data, error } = await getAsset(id);

  if (data) {
    clearError();
    assetSubject.next(data);
  }
  if (error) addError('asset', error.message);

  assetLoadingSubject.next(false);
};

export const fetchAssets = async (filters?: FilterAssetDto) => {
  cruxAssetsLoadingSubject.next(true);

  const { data, error } = await listAssets(filters);

  if (data) {
    clearError();
    cruxAssetsSubject.next(data);
  }
  if (error) addError('asset', error.message);

  cruxAssetsLoadingSubject.next(false);
};
