import { Asset, FilterAssetDto } from '../../store/Asset';
import { PaginatedResponse } from '../../store/GeneralStore';
import { buildQueryURL } from '../../utils/helpers';
import { callExternalApi } from '../callExternalApi';

const API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL;

export const getAsset = (id: string) => {
  return callExternalApi<Asset>({
    url: `${API_SERVER_URL}/assets/${id}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  });
};

export const listAssets = (filters?: FilterAssetDto) => {
  const mergedFilters: FilterAssetDto = {
    page: 1,
    pageSize: 100,
    ...filters
  };

  return callExternalApi<PaginatedResponse<Asset>>({
    url: buildQueryURL(mergedFilters, 'assets'),
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  });
};
