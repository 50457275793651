import { useEffect, useState } from 'react';
import { PageLayout } from '../../PageLayout';
import { UpstackDataGrid } from '../../Compounds/UpstackDataGrid/UpstackDataGrid';
import { getGridColumns } from '../../Compounds/UpstackDataGrid/helpers';
import { GridSingleSelectColDef } from '@mui/x-data-grid-pro';
import { getDefaultPreferences, getUserSubject, GridPreferences } from '../../../store/User';
import { Tab, Tabs, useTheme } from '@mui/material';
import { TabPanel } from '../../Atoms/TabPanel';
import { PaginatedResponse } from '../../../store/GeneralStore';
import {
  Asset,
  fetchAssets,
  FilterAssetDto,
  getCruxAssetsLoadingSubject,
  getCruxAssetsSubject
} from '../../../store/Asset';
import { DefaultAssetGridColumnDefs } from './AssetGridColumnDefs';

export const AssetPage = () => {
  const [assetsLoading, setAssetsLoading] = useState<boolean>(false);
  const [assets, setAssets] = useState<PaginatedResponse<Asset>>();
  const [assetColumns, setAssetColumns] = useState<GridSingleSelectColDef[]>();
  const [gridSettings, setGridSettings] = useState<GridPreferences>(
    getDefaultPreferences()?.content['assetGrid'] as GridPreferences
  );
  const [selectedTab, setSelectedTab] = useState(0);
  const theme = useTheme();
  const tabs = [{ name: 'All' }];

  const handleFetchAssets = (filters?: FilterAssetDto) => {
    fetchAssets(filters);
  };

  const handleTabChange = (index: number) => {
    setSelectedTab(index);
  };

  useEffect(() => {
    const loadingSubscription = getCruxAssetsLoadingSubject().subscribe((loading) => setAssetsLoading(loading));
    const assetSub = getCruxAssetsSubject().subscribe((a) => setAssets(a));
    const userSub = getUserSubject().subscribe((usr) => {
      if (usr?.preferences?.content?.customerGrid) {
        setGridSettings(usr.preferences.content.assetGrid);
      }
    });

    handleFetchAssets();

    return () => {
      if (loadingSubscription) loadingSubscription.unsubscribe();
      if (assetSub) assetSub.unsubscribe();
      if (userSub) userSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!assetsLoading && assets?.data && assets?.data?.length > 0) {
      setAssetColumns(getGridColumns(assets?.data || [], DefaultAssetGridColumnDefs, 'assetGrid'));
    }
  }, [assetsLoading, assets?.data?.length]);

  return (
    <PageLayout
      dataTestId="asset-page"
      pageTitle="Assets">
      <>
        <Tabs
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'rgb(247 247 247)',
            borderBottom: '1px solid rgb(229 231 235)',
            borderTop: '1px solid rgb(229 231 235)',
            paddingRight: '28rem',
            maxWidth: '100%',
            marginTop: '.5rem'
          }}
          TabIndicatorProps={{
            sx: {
              backgroundColor: theme.palette.primary.main,
              height: selectedTab <= tabs.length - 1 ? 2 : 0,
              borderRadius: 1
            }
          }}
          value={selectedTab}
          variant="scrollable"
          scrollButtons="auto"
          onChange={(_e, index) => handleTabChange(index)}>
          {tabs.map((tab, index) => (
            <Tab
              sx={{ textTransform: 'none', fontSize: '.8rem', color: 'black' }}
              key={index}
              label={`${tab.name}`}
            />
          ))}
        </Tabs>
        {tabs.map((tab, index) => (
          <TabPanel
            key={`${index}`}
            index={index}
            value={selectedTab}>
            <UpstackDataGrid
              title="Assets"
              rows={assets?.data || []}
              columns={assetColumns}
              loadingData={assetsLoading}
              page="assetGrid"
              gridSettings={gridSettings}
              showSearch={true}
              apiFilter={true}
              fetchFunction={handleFetchAssets}
              rowCount={assets?.totalCount}
              filterKeys={['sf_id', 'accountId', 'name', 'supplierId']}
            />
          </TabPanel>
        ))}
      </>
    </PageLayout>
  );
};
