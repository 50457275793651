import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { getUser, listUsers, updateUserData } from '../Api/User/UserApi';
import { addError } from './Error';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { GridColumnVisibilityModel, GridSingleSelectColDef } from '@mui/x-data-grid-pro';
import { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
export interface UserEvent {
  id?: string;
  user: User;
  eventType: string;
  content: Record<string, unknown>;
  createdAt?: Date;
  updatedAt?: Date;
}
export interface GridPreferences {
  muiConfig: GridInitialStatePro;
  disabledFields: string[];
  customView?: boolean;
  id?: string;
}

export interface UserPreferencesContent {
  userGrid: GridPreferences;
  commissionGrid: GridPreferences;
  customerGrid: GridPreferences;
  assetGrid: GridPreferences;
}

export interface UserPreferences {
  content: UserPreferencesContent;
  id?: number;
  userId?: number;
}

export enum UserRole {
  ADMIN = 'ADMIN',
  ANALYST = 'ANALYST',
  OBSERVER = 'OBSERVER'
}

export interface User {
  id: string;
  email: string;
  role: UserRole;
  firstName: string;
  lastName: string;
  lastLogin?: Date;
  createdAt?: Date;
  updatedAt?: Date;
  events: UserEvent[];
  preferences: UserPreferences;
}

export type PrefKey = keyof UserPreferencesContent;

export const gridInitialState = (cols?: GridSingleSelectColDef[]) => {
  const visibility: GridColumnVisibilityModel = {};
  if (cols) cols.forEach(({ field }: GridSingleSelectColDef) => (visibility[field] = true));

  return {
    pinnedColumns: {},
    columns: {
      columnVisibilityModel: visibility,
      orderedFields: cols ? cols.map(({ field }) => field) : [],
      dimensions: {}
    },
    preferencePanel: {
      open: false
    },
    filter: {
      filterModel: {
        items: [],
        logicOperator: 'and',
        quickFilterValues: [],
        quickFilterLogicOperator: 'and'
      }
    },
    sorting: {
      sortModel: []
    },
    pagination: {
      paginationModel: {
        page: 0,
        pageSize: 100
      }
    }
  };
};

const _defaultUserPreferences: UserPreferences = {
  content: {
    userGrid: {
      muiConfig: gridInitialState() as GridInitialStatePro,
      disabledFields: []
    },
    commissionGrid: {
      muiConfig: gridInitialState() as GridInitialStatePro,
      disabledFields: []
    },
    customerGrid: {
      muiConfig: gridInitialState() as GridInitialStatePro,
      disabledFields: []
    },
    assetGrid: {
      muiConfig: gridInitialState() as GridInitialStatePro,
      disabledFields: []
    }
  }
};

export const getDefaultPreferences = (): UserPreferences => JSON.parse(JSON.stringify(_defaultUserPreferences));

const userSubject = new BehaviorSubject<User | undefined>(undefined);
const cruxUsersSubject = new BehaviorSubject<User[]>([] as User[]);
const userLoadingSubject = new BehaviorSubject<boolean>(false);
const cruxUsersLoadingSubject = new BehaviorSubject<boolean>(false);
const userDetailSubject = new BehaviorSubject<User | undefined>(undefined);

const clearError = () => addError('user', undefined);

export const getUserDetailSubject = (): BehaviorSubject<User | undefined> => userDetailSubject;
export const getUserSubject = (): BehaviorSubject<User | undefined> => userSubject;
export const getCruxUsersSubject = (): BehaviorSubject<User[]> => cruxUsersSubject;
export const getCruxUsersLoadingSubject = (): BehaviorSubject<boolean> => cruxUsersLoadingSubject;

export const getUserLoadingSubject = (): BehaviorSubject<boolean> => userLoadingSubject;
export const getCurrentUser = (): User | undefined => userSubject.getValue();
export const getCurrentPreferences = (): UserPreferences =>
  JSON.parse(JSON.stringify(userSubject.getValue()?.preferences || ({} as UserPreferences)));

export const isAdmin = () => getCurrentUser()?.role === UserRole.ADMIN;

export const updateUserDetail = (userData: User) => userDetailSubject.next(userData);

export const updateUser = async (userUpdate: Partial<User>) => {
  const userData: User = { ...(userSubject?.getValue() || ({} as User)), ...userUpdate };
  const { data, error } = await updateUserData(userData);

  if (data) {
    clearError();
    const mergedPreferences = {
      content: {
        ...getDefaultPreferences()?.content,
        ...data?.preferences?.content
      }
    };
    data.preferences = mergedPreferences;
    userSubject.next(data);
  }
  if (error) addError('user', error.message);
};

export const fetchUsers = async () => {
  cruxUsersLoadingSubject.next(true);

  const { data, error } = await listUsers();

  if (data) {
    clearError();
    cruxUsersSubject.next(data);
  }
  if (error) addError('user', error.message);

  cruxUsersLoadingSubject.next(false);
};

export interface UseCruxUserReturn {
  fetchUser: () => Promise<void>;
}

export const useCruxUser = (): UseCruxUserReturn => {
  const { authState } = useOktaAuth();

  useEffect(() => {
    if (authState?.isAuthenticated) {
      localStorage.setItem('token', authState?.accessToken?.accessToken || '');
      fetchUser();
    }
  }, [authState, authState?.isAuthenticated]);

  const fetchUser = async () => {
    userLoadingSubject.next(true);

    const { data, error } = await getUser();

    if (data) {
      clearError();
      const mergedPreferences = {
        content: {
          ...getDefaultPreferences()?.content,
          ...data?.preferences?.content
        }
      };
      data.preferences = mergedPreferences;
      userSubject.next(data);
    }
    if (error) addError('user', error.message);

    userLoadingSubject.next(false);
  };

  return { fetchUser };
};
