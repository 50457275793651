import axios, { AxiosError, AxiosRequestConfig } from 'axios';

export type CallResult<T> =
  | {
      data: T;
      error: null;
    }
  | {
      data: null;
      error: {
        message: string;
        errorResponse?: AxiosError;
      };
    };

export type ApiUrl = string;
export type CallExternalApiOptions = Omit<AxiosRequestConfig, 'url'> & {
  url: ApiUrl;
};

export async function callExternalApi<T>(config: CallExternalApiOptions, skipAuth = false): Promise<CallResult<T>> {
  const token = localStorage.getItem('token');

  if (!token && !skipAuth) {
    return { data: null, error: { message: 'Not authenticated or missing token' } };
  }

  try {
    const { data } = await axios({
      ...config,
      headers: { ...config.headers, Authorization: `Bearer ${token}` },
      baseURL: `${process.env.REACT_APP_API_SERVER_URL}/`
    });

    return { data, error: null };
  } catch (error) {
    return {
      data: null,
      error: {
        message: (error as Error).message,
        errorResponse: error as AxiosError
      }
    };
  }
}
