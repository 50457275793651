import { DetailViewCard } from '../../../../Compounds/CardWithTitle';
import { EditUserForm } from './EditUserForm';

export function UserDetails() {
  return (
    <div data-testid="user-details-drawer">
      <EditUserForm />
      <DetailViewCard
        key="admin_events"
        title="History">
        <div>user events section</div>
        {/* <UserEventsSection userData={userData} /> */}
      </DetailViewCard>
    </div>
  );
}
