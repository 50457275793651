import { PageLayout } from '../../PageLayout';

export const Dashboard = () => {
  return (
    <PageLayout
      dataTestId="dashboard-page"
      pageTitle="Dashboard">
      <div>page contents</div>
    </PageLayout>
  );
};
